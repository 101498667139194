const correctImageUrl = (url: string) => {
    if (url.startsWith('http')) {
        return url;
    }
    if (url.startsWith('//')) {
        return 'https://' + url.substr(2);
    }

    return url;
};

export default correctImageUrl;
