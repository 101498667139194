import React, { useMemo } from 'react';
import ImageBase from '../../atoms/ImageBase/ImageBase';
import ReactMarkdown from 'react-markdown';
import Link from 'next/link';
import { withHostPath } from '../../../helper/getBasePath';

interface ITeaserCard {
    bgImage: string;
    title?: string;
    text?: string;
    ctaLabel?: string;
    ctaLink?: string;
    ignoreBasePath?: boolean;
}

export const CardSm: React.FC<ITeaserCard> = ({
    bgImage = '',
    title = '',
    text = '',
    ctaLink,
    ctaLabel,
    ignoreBasePath = false,
}) => {
    const finalHref = useMemo(() => {
        const link = ctaLink?.replace('https://www.marketingcenter.remax.eu', '') ?? '';

        if (link.includes('content.php')) {
            const regex = /content\.php\?category=(\d+)/;
            const result = link.match(regex);
            const id = result?.[1] ?? '0';
            return `/category/${id}`;
        }

        return withHostPath(link);
    }, [ctaLink]);
    return (
        <div className="relative flex h-full w-full flex-col">
            {bgImage && finalHref && (
                <Link
                    href={finalHref}
                    className="aspect-w-1 aspect-h-1 relative flex h-full w-full"
                >
                    <ImageBase
                        className="h-full w-full transition-all duration-1000 hover:scale-105"
                        src={bgImage}
                        alt="Teaser Image"
                        layout="fill"
                        objectFit="cover"
                        ignoreBasePath={ignoreBasePath}
                        placeholder="blur"
                        // https://png-pixel.com/
                        blurDataURL="/shop/images/blur/1x1-dcdcdc51.png"
                    />
                </Link>
            )}

            {ctaLabel && (
                <a
                    className="buttonDefault absolute bottom-4 right-4"
                    href={ctaLink}
                    target="_parent"
                >
                    {ctaLabel}
                </a>
            )}

            {title?.length > 0 && (
                <div className="mt-6">
                    <h3 className="text-sm font-medium text-primary">{title}</h3>
                    <p className="mt-2 text-xs text-gray-500">
                        <ReactMarkdown>{text}</ReactMarkdown>
                    </p>
                </div>
            )}
        </div>
    );
};
