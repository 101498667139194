import { v4 as uuidv4 } from 'uuid';
import React, { FunctionComponent, useEffect } from 'react';
import { CardLgH } from '../components/molecules/CardLgH/CardLgH';
import correctImageUrl from '../helper/correctCMSUrl';
import { CardMd } from '../components/molecules/CardMd/CardMd';
import { CardSm } from '../components/molecules/CardSm/CardSm';
import VideoCard from '../components/molecules/VideoCard/VideoCard';
import { useCMSHomePage } from '../hooks/api/gql/cms/useCMSHomePage';
import { nanoid } from 'nanoid';
import { Scene } from '../components/Scenes/Scene';
import { toast } from 'react-toastify';
import { useSearchParams } from 'next/navigation';
import { HomeCardsTeaser } from '../components/HomeCardsTeaser/HomeCardsTeaser';
import { ProductCarousel } from '../components/ProductCarousel/ProductCarousel';
import { NewsModal } from '../components/NewsModal/NewsModal';

const HomeIndex: FunctionComponent = () => {
    const { data: homeData } = useCMSHomePage();

    const query = useSearchParams();

    useEffect(() => {
        if (query.has('orderSuccess') && !!query.get('orderSuccess')) {
            toast.success('Bestellung aufgegeben');
        }
    }, [query]);

    return (
        <>
            <Scene>
                {homeData !== undefined && (
                    <div className="bg-white">
                        <div className="mx-auto px-4 pt-0 pb-3 sm:py-0 sm:px-4 lg:max-w-6xl lg:px-0">
                            {/* Teaser NEW */}
                            <div className="mb-7 mt-3 grid grid-cols-5 gap-3">
                                <ProductCarousel numberToShow={20} randomize={true} />
                                <HomeCardsTeaser numberToShow={3} />
                            </div>
                            {/* Homegrid */}
                            <div id="homeGrid" className="grid grid-cols-12 gap-3">
                                <div className="col-span-4">
                                    <div className="grid grid-cols-1 grid-rows-1 gap-3">
                                        {/* Small Teaser on left hand side */}
                                        {homeData.cardsTeaser.map((item) => {
                                            if (item.youTubeLink) {
                                                return (
                                                    <VideoCard
                                                        key={uuidv4()}
                                                        url={item.youTubeLink}
                                                        title={item.title}
                                                    />
                                                );
                                            } else {
                                                // Standard Card

                                                return (
                                                    <div
                                                        className="col-span-1 bg-gray-200"
                                                        key={uuidv4()}
                                                    >
                                                        <CardSm
                                                            bgImage={
                                                                correctImageUrl(item.image ?? '') ??
                                                                ''
                                                            }
                                                            text={item.content ?? ''}
                                                            ctaLabel={item.ctaLabel ?? ''}
                                                            ctaLink={item.ctaLink}
                                                            ignoreBasePath={true}
                                                        />
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="col-span-8 bg-gray-200">
                                    {homeData?.teaser.map((item, index: number) => {
                                        if (index !== 0)
                                            return <div className="hidden" key={nanoid()} />;

                                        return (
                                            <CardLgH
                                                key={uuidv4()}
                                                bgImage={correctImageUrl(item.image ?? '') ?? ''}
                                                ctaLabel={item.ctaLabel}
                                                ctaLink={item.ctaLink}
                                                title={item.title}
                                                text={item.content}
                                                ignoreBasePath={true}
                                                youtubeUrl={item.youTubeLink ?? null}
                                            />
                                        );
                                    })}
                                </div>
                                <div className="col-span-12 grid grid-cols-12 gap-3">
                                    {homeData?.teaser.map((item, index: number) => {
                                        // Do not show the first item - it's intended to be continuous flow
                                        if (index === 0) {
                                            return <div className="hidden" key={nanoid()} />;
                                        }
                                        return (
                                            <div
                                                className={`col-span-${
                                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                                    homeData?.cards.length >= 2 ? '4' : '6'
                                                } bg-gray-200`}
                                                key={uuidv4()}
                                            >
                                                <CardMd
                                                    key={uuidv4()}
                                                    bgImage={
                                                        correctImageUrl(item.image ?? '') ?? ''
                                                    }
                                                    ctaLabel={item.ctaLabel}
                                                    ctaLink={item.ctaLink}
                                                    title={item.title}
                                                    text={item.content}
                                                    youtubeUrl={item.youTubeLink ?? null}
                                                    ignoreBasePath={true}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <NewsModal />
                {/*<WidgetList widgets={homeData?.widgets} />*/}

                {/*{embed !== undefined && embed !== '1' && <Footer />}*/}
            </Scene>
        </>
    );
};

export default HomeIndex;
