import React, { useMemo } from 'react';
import Link from 'next/link';
import { IProduct } from '../../interfaces/product/product';

export interface IProductCarouselItem {
    product: IProduct;
}

export const ProductCarouselItem: React.FC<IProductCarouselItem> = (props) => {
    const { product } = props;

    const link = useMemo(() => {
        return `/productoverview/${product.id}`;
    }, [product.id]);

    return (
        <Link href={link}>
            <div
                className="flex aspect-[1/1] h-[221px] w-[221px] items-center justify-center"
                key={product.id}
            >
                <div className="flex h-[221px] w-[221px] items-start justify-center overflow-hidden">
                    <img src={product.thumbnail} alt={product.name} className=" object-cover " />
                </div>
            </div>
            <span className="mt-1 flex w-full items-center justify-center self-end px-4 text-center font-medium text-sm text-primary">
                {product.translation ?? product.name ?? '-'}
            </span>
        </Link>
    );
};
