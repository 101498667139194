import React from 'react';

interface IVideoCard {
    url: string;
    title?: string;
}

const VideoCard: React.FC<IVideoCard> = (props) => {

    const {url, title} = props;

    return <>
        <div className="relative w-full h-full flex aspect-h-1 aspect-w-1">
            <iframe
                src={url}
                title={title ?? "YouTube video player"}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    </>
}

export default VideoCard;
