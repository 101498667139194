import { useApi } from '../../useApi';
import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { HomePageModel } from '../../../../interfaces/cms/homePage.model';
import { useUserLanguage } from '../../user/useUserLanguage';

export const useCMSHomePage = () => {
    const { postGql } = useApi();

    const language = useUserLanguage();

    return useQuery(
        ['cms', 'home', language],
        async () => {
            const { cmsHomePage } = await postGql<{ cmsHomePage: HomePageModel }>(
                gql`
                    query ($input: HomePageInput!) {
                        cmsHomePage(input: $input) {
                            cards {
                                content
                                ctaLabel
                                ctaLink
                                image
                                title
                                youTubeLink
                            }
                            cardsTeaser {
                                content
                                ctaLabel
                                ctaLink
                                image
                                title
                                youTubeLink
                            }
                            teaser {
                                content
                                ctaLabel
                                ctaLink
                                image
                                title
                                youTubeLink
                            }
                        }
                    }
                `,
                { input: { stage: false, language } }
            );

            return cmsHomePage;
        },
        { staleTime: 60000, enabled: !!language }
    );
};
