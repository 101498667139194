import React, { useMemo } from 'react';
import ImageBase from '../../atoms/ImageBase/ImageBase';
import ReactMarkdown from 'react-markdown';
import Link from 'next/link';
import { withHostPath } from '../../../helper/getBasePath';

interface ITeaserCard {
    bgImage: string;
    title?: string;
    text?: string;
    ctaLabel?: string;
    ctaLink?: string;
    ignoreBasePath?: boolean;
    youtubeUrl: string | null;
}

export const CardLgH: React.FC<ITeaserCard> = ({
    bgImage = '',
    title = '',
    text = '',
    ctaLink,
    ctaLabel,
    ignoreBasePath = false,
    youtubeUrl = null,
}) => {
    const finalHref = useMemo(() => {
        const link = ctaLink?.replace('https://www.marketingcenter.remax.eu', '') ?? '';

        if (link.includes('content.php')) {
            const regex = /content\.php\?category=(\d+)/;
            const result = link.match(regex);
            const id = result?.[1] ?? '0';
            return `/category/${id}`;
        }

        return withHostPath(link);
    }, [ctaLink]);

    return (
        <div className="relative flex h-full w-full flex-col gap-0">
            {youtubeUrl && (
                <div className="aspect-w-4 aspect-h-3 relative flex h-full w-full">
                    <iframe
                        src={youtubeUrl}
                        title={title ?? 'YouTube video player'}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
            )}

            {!youtubeUrl && bgImage && finalHref && (
                <Link
                    href={finalHref}
                    className="aspect-w-4 aspect-h-3 relative flex h-full w-full"
                >
                    <ImageBase
                        src={bgImage}
                        className="h-full w-full transition-all duration-1000 hover:scale-105"
                        alt="Teaser Image"
                        layout="fill"
                        objectFit="cover"
                        ignoreBasePath={ignoreBasePath}
                        placeholder="blur"
                        // https://png-pixel.com/
                        blurDataURL="/shop/images/blur/1x1-dcdcdc51.png"
                        priority={true}
                    />
                </Link>
            )}

            <div className="relative bg-gray-100 p-6">
                {title?.length > 0 && (
                    <div className="relative flex flex-col">
                        <h3 className="text-3xl font-medium text-primary">{title}</h3>
                        <div className="mt-2 mb-7 text-sm text-gray-500">
                            <ReactMarkdown>{text}</ReactMarkdown>
                        </div>
                    </div>
                )}
                {ctaLabel && (
                    <div className="relative flex w-full flex-row justify-end">
                        <a className="buttonDefault" href={ctaLink} target="_parent">
                            {ctaLabel}
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};
