import React, { useMemo } from 'react';
import { useNewsModal } from './hooks/useNewsModal';
import { Modal } from '../atoms/Modal/Modal';
import { ContentPage } from '../Content/ContentPage';
import { useUser } from '../../hooks/api/gql/user/queries/useUser';

interface INewsModal {}

/**
 * TODO
 * Publish key als localstorage
 * @constructor
 */

export const NewsModal: React.FC<INewsModal> = () => {
    const { showModal } = useNewsModal();

    const { data } = useUser();

    // TODO remove flag if feature will get live
    const isAdmin = useMemo(() => {
        if (!data) return false;

        return data.id === '4444444444444';
    }, [data]);

    return (
        <>
            <Modal show={showModal && isAdmin} afterClose={() => {}} closeable={true}>
                <ContentPage slug="newsmodal" />
            </Modal>
        </>
    );
};
