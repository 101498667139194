import React, { useMemo } from 'react';
import ImageBase from '../../atoms/ImageBase/ImageBase';
import ReactMarkdown from 'react-markdown';
import { withBasePath, withHostPath } from '../../../helper/getBasePath';

interface ITeaserCard {
    bgImage: string;
    title?: string;
    text?: string;
    ctaLink?: string;
    ignoreBasePath?: boolean;
}

export const TeaserCard: React.FC<ITeaserCard> = ({
    bgImage = '',
    title = '',
    text = '',
    ignoreBasePath = false,
    ctaLink = '',
}) => {
    const finalHref = useMemo(() => {
        const link = ctaLink?.replace('https://www.marketingcenter.remax.eu', '') ?? '';

        if (link.includes('content.php')) {
            const regex = /content\.php\?category=(\d+)/;
            const result = link.match(regex);
            const id = result?.[1] ?? '0';
            return `/category/${id}`;
        }

        return withHostPath(link);
    }, [ctaLink]);

    return (
        <a href={withBasePath(finalHref)}>
            {bgImage && (
                <ImageBase
                    src={bgImage}
                    alt="Teaser Image"
                    width="414"
                    height="414"
                    ignoreBasePath={ignoreBasePath}
                    placeholder="blur"
                    // https://png-pixel.com/
                    blurDataURL="/shop/images/blur/1x1-dcdcdc51.png"
                />
            )}

            <div>
                <h3 className="flex w-full items-center justify-center text-sm font-medium text-primary">
                    {title}
                </h3>
                <p className="mt-2 text-xs text-gray-500">
                    <ReactMarkdown>{text}</ReactMarkdown>
                </p>
            </div>
        </a>
    );
};
