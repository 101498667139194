import React, { useRef } from 'react';
import { useProductCarousel } from './hooks/useProductCarousel';
import { ProductCarouselItem } from './ProductCarouselItem';
import 'swiper/css';
import { Autoplay } from 'swiper';
import { Swiper, SwiperProps, SwiperRef, SwiperSlide } from 'swiper/react';

export interface IProductCarousel {
    numberToShow: number;
    randomize?: boolean;
}

export const ProductCarousel: React.FC<IProductCarousel> = (props) => {
    const { numberToShow, randomize } = props;

    const swiperRef =
        useRef<
            React.MutableRefObject<
                | SwiperRef
                | React.FunctionComponent<React.RefAttributes<SwiperRef> & SwiperProps>
                | null
            >
        >(null); // Create

    const { productsForCarousel } = useProductCarousel({ numberToShow: numberToShow, randomize });

    return (
        <div className="col-span-2">
            <Swiper
                ref={swiperRef as never}
                className="relative flex h-[285px] w-[calc(221px+221px+10px)] flex-row"
                modules={[Autoplay]}
                spaceBetween={10}
                loop={false}
                centeredSlidesBounds={false}
                slidesPerView={2}
                freeMode={false}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                    pauseOnMouseEnter: true,
                }}
            >
                {productsForCarousel.map((product) => {
                    return (
                        <SwiperSlide key={product.id}>
                            <ProductCarouselItem product={product} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};

ProductCarousel.defaultProps = {
    numberToShow: 2,
};
