import { useEffect, useRef, useState } from 'react';

export const useNewsModal = () => {
    const [showModal, setShowModal] = useState(false);

    const isInit = useRef<boolean | null>(null);

    useEffect(() => {
        if (isInit.current) return;

        const session = sessionStorage.getItem('showNewsModal');

        if (session === null) {
            sessionStorage.setItem('showNewsModal', 'true');
            setShowModal(true);
            isInit.current = true;
        }
    }, []);

    return { showModal };
};
