import { useApi } from '../../useApi';
import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { ICategory } from '../../../../interfaces/categories/category';

export const useCategory = (categoryId: number) => {
    const { postGql } = useApi();

    return useQuery(
        ['category', categoryId],
        async () => {
            const { category } = await postGql<{ category: ICategory }>(
                gql`
                    query ($id: Int!) {
                        category(id: $id) {
                            id
                            name
                            translation
                            code
                            viewType
                            viewTypeOnSubItems
                            parentCategory {
                                id
                                name
                                translation
                                code
                                viewType
                                viewTypeOnSubItems
                                products {
                                    id
                                }
                                image
                                headline
                                copyText
                                parentCategory {
                                    id
                                    name
                                    translation
                                    code
                                    viewType
                                    viewTypeOnSubItems
                                    products {
                                        id
                                    }
                                    image
                                    headline
                                    copyText
                                    parentCategory {
                                        id
                                        name
                                        translation
                                        code
                                        viewType
                                        viewTypeOnSubItems
                                        products {
                                            id
                                        }
                                        image
                                        headline
                                        copyText
                                    }
                                }
                            }
                            products {
                                id
                                name
                                translation
                                thumbnail
                            }
                            subCategories {
                                id
                                name
                                translation
                                code
                                viewType
                                viewTypeOnSubItems
                                products {
                                    id
                                }
                                image
                                headline
                                copyText
                            }
                            image
                            headline
                            copyText
                        }
                    }
                `,
                { id: categoryId }
            );
            return category;
        },
        { staleTime: 60000, enabled: !!categoryId }
    );
};
