import { useMemo } from 'react';
import { IProduct } from '../../../interfaces/product/product';
import { useCategory } from '../../../hooks/api/gql/categories/useCategory';

export const useProductCarousel = (options?: { numberToShow?: number; randomize?: boolean }) => {
    // https://www.marketingcenter.remax.eu/AdminCategoryEdit.aspx?id=221
    const { data: categoryData, isLoading } = useCategory(221);

    const productsForCarousel = useMemo((): IProduct[] => {
        if (categoryData && !isLoading) {
            const products = categoryData.products;

            if (options?.randomize) {
                // Randomize the order of the products
                products.sort(() => Math.random() - 0.5);
            }

            // Slice the products to show only the number of products defined in the props (options.numberToShow)
            if (options?.numberToShow) {
                const maxNumber = Math.min(options.numberToShow, products.length);
                return products.slice(0, maxNumber);
            }

            return products;
        }

        return [];
    }, [categoryData, isLoading, options?.numberToShow, options?.randomize]);

    return { productsForCarousel };
};
